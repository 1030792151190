.arrangementsContainer {
	width: 100%;
	display: flex;
	padding: 1rem 0;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.cardContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 1rem;
	padding-bottom: 1.5rem;
	gap: 1rem;
}

.arrangementCard {
	width: 320px;
	height: 320px;
	background-size: cover;
	background-color: var(--highlightFaded);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
}

.iconLink {
	font-size: 1.25rem;
}

.overlay {
	position: relative;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	width: 100%;
	color: white;
	background-color: rgba(0, 0, 0,1);
}

.commissionCard {
	height: 100%;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ctaText {
	color: white;
}