.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding: 2rem 0;
}

.contactForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.centeredHorizontally {
  display: flex;
  justify-content: center;
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formGroup input,
.formGroup textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.formGroup textarea {
  resize: vertical;
  min-height: 100px;
}

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: var(--highlight);
  box-shadow: 0 0 0 2px rgba(125, 111, 125, 0.2);
}

.submitButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--highlight);
  color: var(--background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
}

.submitButton:hover {
  opacity: 0.85;
  transform: translateY(-2px);
}

.errorMessage {
  color: #e53935;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: rgba(229, 57, 53, 0.1);
  border-radius: 4px;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .contactContainer {
    padding: 1rem 0;
  }

  .submitButton {
    align-self: center;
    width: 100%;
  }
}