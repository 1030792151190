/* Style Core */
/* Necessary in React for font */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:root{
  --background: #F0EDF0;
  --primary: #000000;
  --highlight: #7D6F7D;
  --highlightFaded: rgba(125, 111, 125, 0.5);
}

:global(body) {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(code) {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:global(html) {
  font-size: 15px;  /* root font size scales to viewport height */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

:global(button) {
  font-family: 'Nunito Sans';
  color: var(--background);
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--highlight);
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 0.25rem 0.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

:global(button:hover) {
  opacity: 0.75;
}

:global(h1) {
  color: var(--primary);
  font-size: 2.5rem; /* scale depending on viewport height */
  letter-spacing: 0.03125rem; /* 0.5px */
  font-weight: 600;
  text-shadow: 1.25px 1.25px 0.6px grey;
  display: block;
}

:global(h2) {
  color: var(--primary);
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
  text-shadow: 1.25px 1.25px 0.75px grey;
  display: block;
}

:global(h3) {
  color: var(--primary);
  font-size: 1.125rem; /* 18px */
  font-weight: 300;
  display: block;
}

:global(h4) {
  color: var(--highlight);
  font-size: 1.0rem; /* 16px */
  font-weight: 300;
}

:global(h5) {
  color: var(--highlight);
  font-size: 0.75rem; /* 12px */
  font-weight: 200;
}

:global(h6) {
  color: var(--highlight);
  font-size: 0.5rem; /* 8px */
  font-weight: 150;
}

:global(p) {
  display: block;
  font-size: 1.125rem; /* 18px */
}

:global(a) {
  color: var(--highlight);
  font-weight: 600; /* slightly darker */
  text-decoration: none;
  text-shadow: 0.2px 0.2px 0px grey;
}

:global(a:hover) {
  color: var(--highlight);
  opacity: 0.5;
  text-decoration: none;
}

:global(li) {
  list-style-type: square;
  font-size: 1.125rem;  /* match paragraph font size */
}

/* in App.js, header -> main -> footer where max-height is 10, 80, 10 respectively */

:global(.mainContainer) {
  box-sizing: border-box;
  padding: 0 2rem;
  width: 100%;
  min-height: 80dvh;
  -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hides scrollbar in Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
:global(.mainContainer::-webkit-scrollbar) {
  width: 0px;
  height: 0px;
  display: none;
}

:global(.fullPageContainer) {
  box-sizing: border-box;
  height: 80dvh;
}

:global(.fullPageContainer::-webkit-scrollbar) {
  display: none;
}

:global(.ctaButtonsContainer) {
  display: flex;
  padding-bottom: 1rem;
  width: 100%;
}

:global(.centeredVertically) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:global(.learnMore) {
  color: var(--highlight);
  font-size: 1.0rem;
  font-weight: 200;
}

:global(.subscript-text) {
  font-weight: 200;
  font-size: 0.7rem;
}

:global(.icon) {
  color: var(--highlight);
  cursor: pointer;
}

:global(.icon):hover {
  opacity: 0.65;
}

:global(.float-left) {
  float: left;
}

:global(.float-right) {
  float: right;
}

/* App.js specific containers: .backgroundContainer and .appContainer */
/* These wrap are a wrapper for all other pages */
.backgroundContainer {
  color: var(--primary);
  background-color: var(--background);
}

.appContainer {
  margin-left: auto;
  margin-right: auto;
  width: 56%;
}

/* Mobile Styling for Containers */
@media only screen and (max-width: 800px) {
  .appContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  :global(.mainContainer) {
    max-height: 80dvh;
  }

  :global(html) {
    font-size: 16px;
  }

  :global(h1) {
    font-size: 2rem;
  }

  :global(p) {
    font-size: 1rem;
  }

  :global(li) {
    font-size: 1rem;  /* match paragraph font size */
  }

  :global(.centeredHorizontallyOnMobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  :global(.textCenteredOnMobile) {
    text-align: center;
  }

  :global(.icon) {
    font-size: 1.15rem;

    /* Increase touch area on mobile: add padding then offset element */
    position: relative;
    padding: 1.5rem;
    margin: -1.5rem;
  }
}