.footerContainer {
  box-sizing: border-box;
  color: var(--highlight);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 10dvh;
  max-height: 10dvh;
  padding: 1rem 0;
  overflow: hidden;
  text-align: right;
  border-top: 1px var(--highlightFaded) solid;
  width: 100%;
  z-index: 1;
}

/* Icons */
.socialLinks {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
}

.socialLinks a {
  display: inline-block;
  color: var(--highlight);
}

.socialLinks i {
  font-size: 1.5rem;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media only screen and (max-width: 800px) {
  .footerContainer {
    text-align: center;
    justify-content: center;
    max-height: 10dvh;
  }

  .socialLinks {
    justify-content: center;
    gap: 1.2rem;
  }

  .socialLinks i {
    font-size: 1.6rem;
  }
}