.headerContainer {
  height: 10dvh;
  overflow: hidden;
  padding-top: 1.5rem;
  border-bottom: 1px var(--highlightFaded) solid;
}

.navLink {
  margin: 0 0.5rem;
}

/* Mobile Styling for Containers */
@media only screen and (max-width: 800px) {
  .headerContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .fullScreenModal {
    /* "Inherit" styles from .headerContainer */
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    overflow: hidden;

    position: absolute;
    height: 100dvh;
    max-height: 100dvh;
    width: 100%;
    background-color: var(--background);
    z-index: 999;
  }

  .modalBodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 25px;
    height: 100%;
  }
}